/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// includes polufill
// findIndex polyfill
// closest polyfill
// promise polyfill

exports.onClientEntry = () => {
  // NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

  // IntersectionObserver polyfill for gatsby-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    require(`intersection-observer`)
    console.log(`👍 IntersectionObserver is polyfilled`)
  }

  // Object-fit/Object-position polyfill for gatsby-image (IE)
  const testImg = document.createElement(`img`)
  if (
    typeof testImg.style.objectFit === `undefined` ||
    typeof testImg.style.objectPosition === `undefined`
  ) {
    require(`object-fit-images`)()
    console.log(`👍 Object-fit/Object-position are polyfilled`)
  }

  if (!Element.prototype.matches || !Element.prototype.closest) {
    require(`element-closest`)
    console.log(`👍 Element closest is polyfilled`)
  }

  require('array-from-polyfill')
  require('whatwg-fetch')

  //find
}

const transitionDelay = 500

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const hash = window.location.hash.substr(1)
  const element = document.getElementById(hash)
  const offsetTop = element
    ? element.getBoundingClientRect().top +
      document.documentElement.scrollTop -
      48
    : 0

  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, offsetTop), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, offsetTop])),
      transitionDelay
    )
  }
  return false
}
